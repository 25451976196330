import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody4';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy4 = ({setBlank}) => {

    const headings = "<h4>Enabling a Real Estate Conglomerate To Secure</h4><h2 style='display:inline-block;color:#ffffff;'> USD 40 Mn funding</h2><h4 style='display:inline-block;'>To Develop A </h4><h4>State-of-the-art logistics park </h4>";

  return (
    <>
        <Helmet>
        <title>Fast-Tracking The Investment Process Through A Structured Process</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-client4.36fb43bb41585c8c3df1.webp" /> 
        <meta property="og:title" content="Fast-Tracking The Investment Process Through A Structured Process" />
        <meta property="og:description" content="Fast-Tracking The Investment Process Through A Structured Process" />
      
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy class="header-casestudy4" headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy4
