import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, useFormik } from 'formik';
import { NavLink } from "react-router-dom";
import { signUpSchema } from "./Signupschema";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'


import './contactbody.css';

const initialValues = {
    name: "",
    company: "",
    subject: "",
    country: "",
    email: "",
    // phone: "",
    message: "",
    agree: false
}


const Contactbody = () => {

    
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
    const rotateicon = () => {
        const el = document.querySelector(".country-box");
        el.classList.toggle("select");
      }

      let countries = [
        { name: 'Afghanistan', code: 'AF' },
        { name: 'Åland Islands', code: 'AX' },
        { name: 'Albania', code: 'AL' },
        { name: 'Algeria', code: 'DZ' },
        { name: 'American Samoa', code: 'AS' },
        { name: 'AndorrA', code: 'AD' },
        { name: 'Angola', code: 'AO' },
        { name: 'Anguilla', code: 'AI' },
        { name: 'Antarctica', code: 'AQ' },
        { name: 'Antigua and Barbuda', code: 'AG' },
        { name: 'Argentina', code: 'AR' },
        { name: 'Armenia', code: 'AM' },
        { name: 'Aruba', code: 'AW' },
        { name: 'Australia', code: 'AU' },
        { name: 'Austria', code: 'AT' },
        { name: 'Azerbaijan', code: 'AZ' },
        { name: 'Bahamas', code: 'BS' },
        { name: 'Bahrain', code: 'BH' },
        { name: 'Bangladesh', code: 'BD' },
        { name: 'Barbados', code: 'BB' },
        { name: 'Belarus', code: 'BY' },
        { name: 'Belgium', code: 'BE' },
        { name: 'Belize', code: 'BZ' },
        { name: 'Benin', code: 'BJ' },
        { name: 'Bermuda', code: 'BM' },
        { name: 'Bhutan', code: 'BT' },
        { name: 'Bolivia', code: 'BO' },
        { name: 'Bosnia and Herzegovina', code: 'BA' },
        { name: 'Botswana', code: 'BW' },
        { name: 'Bouvet Island', code: 'BV' },
        { name: 'Brazil', code: 'BR' },
        { name: 'British Indian Ocean Territory', code: 'IO' },
        { name: 'Brunei Darussalam', code: 'BN' },
        { name: 'Bulgaria', code: 'BG' },
        { name: 'Burkina Faso', code: 'BF' },
        { name: 'Burundi', code: 'BI' },
        { name: 'Cambodia', code: 'KH' },
        { name: 'Cameroon', code: 'CM' },
        { name: 'Canada', code: 'CA' },
        { name: 'Cape Verde', code: 'CV' },
        { name: 'Cayman Islands', code: 'KY' },
        { name: 'Central African Republic', code: 'CF' },
        { name: 'Chad', code: 'TD' },
        { name: 'Chile', code: 'CL' },
        { name: 'China', code: 'CN' },
        { name: 'Christmas Island', code: 'CX' },
        { name: 'Cocos (Keeling) Islands', code: 'CC' },
        { name: 'Colombia', code: 'CO' },
        { name: 'Comoros', code: 'KM' },
        { name: 'Congo', code: 'CG' },
        { name: 'Congo, The Democratic Republic of the', code: 'CD' },
        { name: 'Cook Islands', code: 'CK' },
        { name: 'Costa Rica', code: 'CR' },
        { name: 'Cote D\'Ivoire', code: 'CI' },
        { name: 'Croatia', code: 'HR' },
        { name: 'Cuba', code: 'CU' },
        { name: 'Cyprus', code: 'CY' },
        { name: 'Czech Republic', code: 'CZ' },
        { name: 'Denmark', code: 'DK' },
        { name: 'Djibouti', code: 'DJ' },
        { name: 'Dominica', code: 'DM' },
        { name: 'Dominican Republic', code: 'DO' },
        { name: 'Ecuador', code: 'EC' },
        { name: 'Egypt', code: 'EG' },
        { name: 'El Salvador', code: 'SV' },
        { name: 'Equatorial Guinea', code: 'GQ' },
        { name: 'Eritrea', code: 'ER' },
        { name: 'Estonia', code: 'EE' },
        { name: 'Ethiopia', code: 'ET' },
        { name: 'Falkland Islands (Malvinas)', code: 'FK' },
        { name: 'Faroe Islands', code: 'FO' },
        { name: 'Fiji', code: 'FJ' },
        { name: 'Finland', code: 'FI' },
        { name: 'France', code: 'FR' },
        { name: 'French Guiana', code: 'GF' },
        { name: 'French Polynesia', code: 'PF' },
        { name: 'French Southern Territories', code: 'TF' },
        { name: 'Gabon', code: 'GA' },
        { name: 'Gambia', code: 'GM' },
        { name: 'Georgia', code: 'GE' },
        { name: 'Germany', code: 'DE' },
        { name: 'Ghana', code: 'GH' },
        { name: 'Gibraltar', code: 'GI' },
        { name: 'Greece', code: 'GR' },
        { name: 'Greenland', code: 'GL' },
        { name: 'Grenada', code: 'GD' },
        { name: 'Guadeloupe', code: 'GP' },
        { name: 'Guam', code: 'GU' },
        { name: 'Guatemala', code: 'GT' },
        { name: 'Guernsey', code: 'GG' },
        { name: 'Guinea', code: 'GN' },
        { name: 'Guinea-Bissau', code: 'GW' },
        { name: 'Guyana', code: 'GY' },
        { name: 'Haiti', code: 'HT' },
        { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
        { name: 'Holy See (Vatican City State)', code: 'VA' },
        { name: 'Honduras', code: 'HN' },
        { name: 'Hong Kong', code: 'HK' },
        { name: 'Hungary', code: 'HU' },
        { name: 'Iceland', code: 'IS' },
        { name: 'India', code: 'IN' },
        { name: 'Indonesia', code: 'ID' },
        { name: 'Iran, Islamic Republic Of', code: 'IR' },
        { name: 'Iraq', code: 'IQ' },
        { name: 'Ireland', code: 'IE' },
        { name: 'Isle of Man', code: 'IM' },
        { name: 'Israel', code: 'IL' },
        { name: 'Italy', code: 'IT' },
        { name: 'Jamaica', code: 'JM' },
        { name: 'Japan', code: 'JP' },
        { name: 'Jersey', code: 'JE' },
        { name: 'Jordan', code: 'JO' },
        { name: 'Kazakhstan', code: 'KZ' },
        { name: 'Kenya', code: 'KE' },
        { name: 'Kiribati', code: 'KI' },
        { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
        { name: 'Korea, Republic of', code: 'KR' },
        { name: 'Kuwait', code: 'KW' },
        { name: 'Kyrgyzstan', code: 'KG' },
        { name: 'Lao People\'S Democratic Republic', code: 'LA' },
        { name: 'Latvia', code: 'LV' },
        { name: 'Lebanon', code: 'LB' },
        { name: 'Lesotho', code: 'LS' },
        { name: 'Liberia', code: 'LR' },
        { name: 'Libyan Arab Jamahiriya', code: 'LY' },
        { name: 'Liechtenstein', code: 'LI' },
        { name: 'Lithuania', code: 'LT' },
        { name: 'Luxembourg', code: 'LU' },
        { name: 'Macao', code: 'MO' },
        { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
        { name: 'Madagascar', code: 'MG' },
        { name: 'Malawi', code: 'MW' },
        { name: 'Malaysia', code: 'MY' },
        { name: 'Maldives', code: 'MV' },
        { name: 'Mali', code: 'ML' },
        { name: 'Malta', code: 'MT' },
        { name: 'Marshall Islands', code: 'MH' },
        { name: 'Martinique', code: 'MQ' },
        { name: 'Mauritania', code: 'MR' },
        { name: 'Mauritius', code: 'MU' },
        { name: 'Mayotte', code: 'YT' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Micronesia, Federated States of', code: 'FM' },
        { name: 'Moldova, Republic of', code: 'MD' },
        { name: 'Monaco', code: 'MC' },
        { name: 'Mongolia', code: 'MN' },
        { name: 'Montserrat', code: 'MS' },
        { name: 'Morocco', code: 'MA' },
        { name: 'Mozambique', code: 'MZ' },
        { name: 'Myanmar', code: 'MM' },
        { name: 'Namibia', code: 'NA' },
        { name: 'Nauru', code: 'NR' },
        { name: 'Nepal', code: 'NP' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'Netherlands Antilles', code: 'AN' },
        { name: 'New Caledonia', code: 'NC' },
        { name: 'New Zealand', code: 'NZ' },
        { name: 'Nicaragua', code: 'NI' },
        { name: 'Niger', code: 'NE' },
        { name: 'Nigeria', code: 'NG' },
        { name: 'Niue', code: 'NU' },
        { name: 'Norfolk Island', code: 'NF' },
        { name: 'Northern Mariana Islands', code: 'MP' },
        { name: 'Norway', code: 'NO' },
        { name: 'Oman', code: 'OM' },
        { name: 'Pakistan', code: 'PK' },
        { name: 'Palau', code: 'PW' },
        { name: 'Palestinian Territory, Occupied', code: 'PS' },
        { name: 'Panama', code: 'PA' },
        { name: 'Papua New Guinea', code: 'PG' },
        { name: 'Paraguay', code: 'PY' },
        { name: 'Peru', code: 'PE' },
        { name: 'Philippines', code: 'PH' },
        { name: 'Pitcairn', code: 'PN' },
        { name: 'Poland', code: 'PL' },
        { name: 'Portugal', code: 'PT' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Qatar', code: 'QA' },
        { name: 'Reunion', code: 'RE' },
        { name: 'Romania', code: 'RO' },
        { name: 'Russian Federation', code: 'RU' },
        { name: 'RWANDA', code: 'RW' },
        { name: 'Saint Helena', code: 'SH' },
        { name: 'Saint Kitts and Nevis', code: 'KN' },
        { name: 'Saint Lucia', code: 'LC' },
        { name: 'Saint Pierre and Miquelon', code: 'PM' },
        { name: 'Saint Vincent and the Grenadines', code: 'VC' },
        { name: 'Samoa', code: 'WS' },
        { name: 'San Marino', code: 'SM' },
        { name: 'Sao Tome and Principe', code: 'ST' },
        { name: 'Saudi Arabia', code: 'SA' },
        { name: 'Senegal', code: 'SN' },
        { name: 'Serbia and Montenegro', code: 'CS' },
        { name: 'Seychelles', code: 'SC' },
        { name: 'Sierra Leone', code: 'SL' },
        { name: 'Singapore', code: 'SG' },
        { name: 'Slovakia', code: 'SK' },
        { name: 'Slovenia', code: 'SI' },
        { name: 'Solomon Islands', code: 'SB' },
        { name: 'Somalia', code: 'SO' },
        { name: 'South Africa', code: 'ZA' },
        { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
        { name: 'Spain', code: 'ES' },
        { name: 'Sri Lanka', code: 'LK' },
        { name: 'Sudan', code: 'SD' },
        { name: 'Suriname', code: 'SR' },
        { name: 'Svalbard and Jan Mayen', code: 'SJ' },
        { name: 'Swaziland', code: 'SZ' },
        { name: 'Sweden', code: 'SE' },
        { name: 'Switzerland', code: 'CH' },
        { name: 'Syrian Arab Republic', code: 'SY' },
        { name: 'Taiwan, Province of China', code: 'TW' },
        { name: 'Tajikistan', code: 'TJ' },
        { name: 'Tanzania, United Republic of', code: 'TZ' },
        { name: 'Thailand', code: 'TH' },
        { name: 'Timor-Leste', code: 'TL' },
        { name: 'Togo', code: 'TG' },
        { name: 'Tokelau', code: 'TK' },
        { name: 'Tonga', code: 'TO' },
        { name: 'Trinidad and Tobago', code: 'TT' },
        { name: 'Tunisia', code: 'TN' },
        { name: 'Turkey', code: 'TR' },
        { name: 'Turkmenistan', code: 'TM' },
        { name: 'Turks and Caicos Islands', code: 'TC' },
        { name: 'Tuvalu', code: 'TV' },
        { name: 'Uganda', code: 'UG' },
        { name: 'Ukraine', code: 'UA' },
        { name: 'United Arab Emirates', code: 'AE' },
        { name: 'United Kingdom', code: 'GB' },
        { name: 'United States', code: 'US' },
        { name: 'United States Minor Outlying Islands', code: 'UM' },
        { name: 'Uruguay', code: 'UY' },
        { name: 'Uzbekistan', code: 'UZ' },
        { name: 'Vanuatu', code: 'VU' },
        { name: 'Venezuela', code: 'VE' },
        { name: 'Viet Nam', code: 'VN' },
        { name: 'Virgin Islands, British', code: 'VG' },
        { name: 'Virgin Islands, U.S.', code: 'VI' },
        { name: 'Wallis and Futuna', code: 'WF' },
        { name: 'Western Sahara', code: 'EH' },
        { name: 'Yemen', code: 'YE' },
        { name: 'Zambia', code: 'ZM' },
        { name: 'Zimbabwe', code: 'ZW' }
      ];

    const [hide, setHide] = useState(false);
    const [modalShow, setModalShow] = useState();
    const [response, setResponse] = useState();
    const [phone, setphone] = useState();
    const [phoneerrmsg, setphoneerrmsg] = useState();
    
    
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: signUpSchema,
            onSubmit: (values, action) => {
              
                if(formatPhoneNumber(phone) === "") {
                    setphoneerrmsg("Please enter your Phone Number");
                } else {

                    setphoneerrmsg();

                    const formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("company", values.company);
                    formData.append("subject", values.subject);
                    formData.append("country", values.country);
                    formData.append("email", values.email);
                    formData.append("phone",  formatPhoneNumberIntl(phone));
                    formData.append("message", values.message);

                    setHide(true);
                    // https://ps-blog.oneweb.agency/wp-json/mail/sendmail
                    // https://blog.preferred-square.com/wp-json/mail/sendmail
                fetch('https://blog.preferred-square.com/wp-json/mail/sendmail', {
                        method: 'POST',
                        mode: 'cors',
                        // headers: {
                        // 'Content-Type': 'application/json',
                        // },
                        
                        // body: JSON.stringify({
                        //     name: values.name,
                        //     company: values.company,
                        //     subject: values.subject,
                        //     country: values.country,
                        //     email: values.email,
                        //     phone: formatPhoneNumberIntl(phone),
                        //     message: values.message,
                        // }),

                        body:formData,
                    })
                .then(res => res.json())
                .then(data => {
                // enter you logic when the fetch is successful
                    setHide(false);
                    setModalShow(true);
                  console.log(data)
                  setResponse(data.message);
                  setTimeout(()=>{
                    setResponse("");
                  }, 2000);
        
                })
            
                // .catch(error => {
                //     // enter your logic for when there is an error (ex. error toast)
                //     console.log(error.message);
                // }) 

                setphone();       
                action.resetForm();
              }
            }
        });

 
    const [forms, setForm] = useState("form1");

    return (
        <>
            <section className='contactbody-section' >
                <Container>
                    <Row className='w-100 mx-auto'>
                        <Col className='col-md-6'>

                            <div className='content-box'>
                                <div className="strok">Contact</div>
                                <div className="h1 anima desktop">Contact <br /> <span className='anima-underline'>Us</span></div>
                                <div className="h1 anima mobile">Contact <span className='anima-underline'>Us</span></div>

                                <div className='address-map mt-5 '>
                                    <iframe title="Address Map" style={{ border: 0 }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.7031439219286!2d77.05427902352508!3d28.442059253087333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f17a5ff558d%3A0x84ecc28985870835!2sPreferred%20Square!5e0!3m2!1sen!2sin!4v1688731138363!5m2!1sen!2sin" width="600" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    {/* <iframe title="Address Map" style={{border:0}}  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.372604981984!2d77.1023046754182!3d28.438182225771445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f52fa539f2d%3A0xd5b637d9ef23ef83!2sVATIKA%20TOWER%2C%20Suncity%2C%20Sector%2054%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1686918094986!5m2!1sen!2sin" width="600" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

                                   {/* <a href='tel:+919810376994' ><div className="callbtn">
                                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                     <path d="M14.6678 12.486L11.1327 13.1762C8.74487 11.9686 7.26986 10.5815 6.41145 8.41922L7.07238 4.84709L5.82303 1.5H2.60319C1.63529 1.5 0.8731 2.3059 1.01766 3.27018C1.37854 5.67748 2.44261 10.0422 5.55304 13.1762C8.81946 16.4673 13.524 17.8954 16.1132 18.4631C17.1131 18.6823 18 17.8964 18 16.8654V13.7654L14.6678 12.486Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                     Call us at : +91 98103 76994 </div> </a>
                                    */}
                                </div>
                        </div>

                    </Col>
                    <Col className='col-md-6'>

                        <div className='content-box margintop'>
                            <h4>Type of enquiry*</h4>

                            <div className='d-flex py-3 text-dark gap-2 justify-content-start' >
                                <div><input type="radio" value="form1" onChange={() => { setForm('form1') }} name="form" checked={forms === 'form1' ? "checked" : ""}  /> Client-service enquiries</div>
                                <div><input type="radio" value="form2" onChange={() => { setForm('form2') }} name="form" checked={forms === 'form2' ? "checked" : ""} /> Recruiting information</div>
                            </div>

                            <div className='form-box'>
                                {/* <div className={forms === 'form1' ? "form1 d-block" : "form1 d-none"}> */}
                                    <Formik>
                                        {(control) => (
                                            <Form onSubmit={handleSubmit}>

                                                <div className='mb-3'>
                                                    <label htmlFor="name" className="">Name*</label>
                                                    <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                    <div className='errormsg'> {errors.name && touched.name ? (
                                                        <p className="form-error">{errors.name}</p>
                                                    ) : null}</div>
                                                </div>
                                           
                                                <div className='mb-3'>
                                                    <label htmlFor="phone" className="">Phone*</label>
                                           
                                                    <PhoneInput placeholder="" name="phone"  aria-labelledby="phone" value={phone}                                                         
                                                       international
                                                       countryCallingCodeEditable={false}
                                                       defaultCountry="IN"
                                                       onChange={setphone}
                                                        rules={{ required: true, validate: isPossiblePhoneNumber }}
                                                        /> 

                                                    <div className='errormsg'> {phoneerrmsg ? (
                                                        <p className="form-error">{phoneerrmsg}</p>
                                                    ) : null}</div>
                                                </div>

                                                

                                                <div className='mb-3'>
                                                    <label htmlFor="email" className="">Email*</label>
                                                    <Field type="email" name="email" placeholder='' aria-labelledby="email" value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {/* <ErrorMessage name="email" component="div" /> */}
                                                    <div className='errormsg'> {errors.email && touched.email ? (
                                                        <p className="form-error">{errors.email}</p>
                                                    ) : null}</div>
                                                </div>

                                                <div className='mb-3'>
                                                    <label htmlFor="company" className="">{forms === 'form1' ? "Company*" : "Company / College Name*"} </label>
                                                    <Field type="text" name="company" placeholder='' aria-labelledby="company" value={values.company}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {/* <ErrorMessage name="company" className="" component="div" /> */}
                                                    <div className='errormsg'> {errors.company && touched.company ? (
                                                        <p className="form-error">{errors.company}</p>
                                                    ) : null}</div>
                                                </div>

                                                <div className='mb-3 country-box'>
                                                    <label htmlFor="country" className="">Country*</label>
                                                    {/* <Field type="text" name="country" placeholder='' aria-labelledby="country" value={values.country}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} /> */}

                                                    <select name="country" className="form-control" onClick={() => rotateicon()} value={values.country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}>
                                                    <option value="0" selected className='country'>Select Country</option>
                                                    {countries.map((country) => {
                                                        return <option key={country.code} value={country.name}>{country.name} ({country.code})</option>
                                                    })
                                                    }
                                                    </select>
                                                    
                                                    {/* <ErrorMessage name="country" className="" component="div" /> */}
                                                    <div className='errormsg'> {errors.country && touched.country ? (
                                                        <p className="form-error">{errors.country}</p>
                                                    ) : null}</div>
                                                </div>

                                                <div className='mb-3'>
                                                    <label htmlFor="subject" className="">Subject*</label>
                                                    <Field type="text" name="subject" placeholder='' aria-labelledby="subject" value={values.subject}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {/* <ErrorMessage name="subject" className="" component="div" /> */}
                                                    <div className='errormsg'> {errors.subject && touched.subject ? (
                                                        <p className="form-error">{errors.subject}</p>
                                                    ) : null}</div>
                                                </div>

                                                <div className='mb-3'>
                                                    <label htmlFor="message" className="">Message*</label>
                                                    <Field component="textarea" name="message" row="10" aria-labelledby="message" value={values.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {/* <ErrorMessage name="message" component="div" /> */}
                                                    <div className='errormsg'> {errors.message && touched.message ? (
                                                        <p className="form-error">{errors.message}</p>
                                                    ) : null}</div>
                                                </div>

                                                <div className='mb-3 text-dark'>
                                                    <Field type="checkbox" name="agree" className="mr-5" aria-labelledby="agree"
                                                        value={values.agree}
                                                        onChange={handleChange}
                                                    />

                                                    I agree to the <NavLink to='/termsandconditions' onClick={scrollToTop}>terms and conditions</NavLink>
                                                    {/* <label className='checkbox-label' >  I agree to the <NavLink to='/termsandconditions' onClick={scrollToTop}>terms and conditions</NavLink></label> */}
                                                   
                                                    {/* <ErrorMessage name="agree" component="div" /> */}

                                                    <p>
                                                        Preferred Square is committed to protecting your information. Your information will be used in accordance with the applicable data privacy law, our internal policies and our privacy policy. As Preferred Square is a global organisation, your information may be stored and processed by Preferred Square and its affiliates in countries outside your country of residence, but wherever your information is processed, we will handle it with the same care and respect for your privacy.
                                                    </p>
                                                    <div className='errormsg'> {errors.agree && touched.agree ? (
                                                        <p className="form-error">{errors.agree}</p>
                                                    ) : null}</div>
                                                </div>


                                                <div className='text-left mobile-align'>
                                                    {(hide) ? (
                                                        <button 
                                                        disabled
                                                        style={{opacity:"0.5"}}
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                    
                                                    ) :  (
                                                        <button type="submit">Submit
                                                        </button>                         
                                                        )
                                                    }
                                                </div>
                                                {/* <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>   */}
                                            </Form>
                                        )}
                                    </Formik>

                                {/* </div> */}
                               
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

                    {/* success popup */}
                    <Modal
                        show={modalShow}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        >
                        {/* <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                            </Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body className='text-center p-5'>
                            {/* {resonseMsg} */}
                            <div className="form-success text-center mt-5 d-none"    dangerouslySetInnerHTML={{ __html: response }}></div>                           
                            <h4>Thank you for your interest in Preferred Square! </h4> <p>Our team will reach out to you.</p>
                             <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            
                        </Modal.Footer> */}
                    </Modal>

        </section >

        </>
    )
}

export default Contactbody
