import React, { useState  } from 'react'
import { Container, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, useFormik } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'
import * as Yup from 'yup';
import './jobopeningbody.css'

// const SUPPORTED_FORMATS = [".doc", ".docx", "application/pdf"];
const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
   // phone: Yup.string().min(10).max(10).required("Please enter your Phone Number"),
  message: Yup.string().min(4).required("Please enter message"),
  resume: Yup.mixed().required("Please select resume").test('fileSize', "File is too large", value => value.size <= 3145728)
  .test("is-valid-type", "Please Select Only doc, docx and pdf file",
  value => isValidFileType(value && value.name.toLowerCase(), "image"))
});


const validFileExtensions = { image: ['doc', 'docx', 'pdf'] };

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}


const initialValues = {
  name: "",
  subject: "Business Analyst",
  email: "",
  // phone: "",
  message: "",
  resume: null 
}



const Jobopeningbody = () => {
  
  const [hide, setHide] = useState(false);
  const [modalShow, setModalShow] = useState();
  const [response, setResponse] = useState();
  const [phone, setphone] = useState();
  const [phoneerrmsg, setphoneerrmsg] = useState();

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const phoneblur = ()=>{ 
    // setphoneerrmsg("Please enter your Phone Number") 
    if (phone === "+91") {
      setphoneerrmsg("Please enter your Phone Number")
    } else {
      setphoneerrmsg("")
    }
  } 

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values, action) => {

        if(formatPhoneNumber(phone) === "") {
          setphoneerrmsg("Please enter your Phone Number")
        } else {
          setphoneerrmsg();

          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("phone", formatPhoneNumberIntl(phone));
          formData.append("subject", values.subject);
          formData.append("message", values.message);         
          formData.append("resume", values.resume);
          
          setHide(true);
        fetch('https://blog.preferred-square.com/wp-json/jobs/jobmail', {
          method: 'POST',
          mode: 'cors',
          //  headers: {
          //   //  'Content-Type': 'application/json',
          //    "Content-Type": "multipart/form-data",
          //  },

          body:formData,
          })


            .then(res => res.json())
            .then(data => {
            // enter you logic when the fetch is successful
              setHide(false);
              setModalShow(true);
              console.log(data)
              setResponse(data);
              setTimeout(()=>{
                setResponse("");
              }, 2000);

            })
        
            // .catch(error => {
            // // enter your logic for when there is an error (ex. error toast)
            // console.log(error)
            // }) 
            
            const fileInput = document.getElementById('file-input');
            if (fileInput) {
              fileInput.value = '';
            }

        setphone();
        action.resetForm();
        }
      },
    });

  // console.log(
  //   errors
  // );


  return (
    <>
      <section>
        <Container className='jobopeningbody-section'>
          {/* <div className='job-upper-div'>
            <button className='career-btn'>Careers</button>
            <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.5 15L13.5 12L10.5 9" stroke="#151515" />
            </svg></div>
            <p className='job-upper-para'>Business Analyst</p>
          </div> */}
          <Row className='mt-5 pt-lg-5 mb-5'>
            <div className='col-md-6 jobinfo-section px-3'>
              <h2> Business Analyst</h2>
              <h5 className='mt-5'>Responsibilities</h5>
              <ul className='mt-3'>
{/* 
              <li>Develop and maintain productive relationships with the client. Focus on deliverables, understand the client business priorities, and manage the project pipelines. Manage expectations of service and provide pragmatic solutions to the clients</li>
              <li>Take charge of analysing problems, discovering insights, delivering recommendations, and presenting findings to clients and stakeholders</li>
              <li>Determine & define project scope and objectives, predict time required & resources involved, and manage resources in an effective and efficient manner</li>
              <li>Handle 8-9 projects at a time with the help of a team of 6-7 consultants/business analysts</li>
              <li>Build a team by providing constructive on-the-job feedback/training. Foster an innovative and team-oriented work environment</li>
              <li>Involved in various business development & digital marketing initiatives such as preparation of pitch documents, writing articles for LinkedIn & website etc.</li> */}


                <li> Assist Senior Consultant / Principal Consultant in project execution and business development</li>
                <li> Support in project planning, execution, and delivery</li>
                <li> Be responsible for specific components of the work including: Feasibility studies, business planning, capital planning & fund raising, business due diligence, financial restructuring Building Financial and Valuation models</li>
                <li> Analysing and summarizing financial performance</li>
                <li> Developing power point and word documents including Pitchbook and Board Presentations</li>
                <li> Identifying KPIs and benchmarking with relevant peers</li>
                <li> Developing conceptual documents and teasers</li>
                <li> Effective and frequent communication with client on all modes of communication</li>
             </ul>
             <h5 className='mt-5'>Requirements</h5>
             <ul>
               <li>Work Ex – 0-1 year</li>
               <li>Educational Qualifications – B.Com, BBA, B.Com(H), BMS (from top universities)</li>
               <li>Skills Set – Financial Modelling, Business Acumen</li>
             </ul>
            </div>
            <div className='col-md-6 form-section px-3'>
              <h2>Apply Now</h2>
              <Formik>
                {() => (
                  <Form onSubmit={handleSubmit} encType="multipart/form-data" className='mt-5'>

                    <div className='mb-3'>
                      <label htmlFor="name" className="">Name*</label>
                      <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="name" className="" component="div" /> */}
                      <div className='errormsg'> {errors.name && touched.name ? (
                        <p className="form-error">{errors.name}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      <label htmlFor="email" className="">Email*</label>
                      <Field type="email" name="email" placeholder='' aria-labelledby="email" value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="email" component="div" /> */}
                      <div className='errormsg'> {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      {/* <label htmlFor="phone" className="">Phone*</label>
                      <Field type="text" name="phone" placeholder='' aria-labelledby="phone" value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <div className='errormsg'> {errors.phone && touched.phone ? (
                        <p className="form-error">{errors.phone}</p>
                      ) : null}</div> */}

                      <PhoneInput placeholder="" name="phone"  aria-labelledby="phone" value={phone}                                                         country="US"
                         international
                         countryCallingCodeEditable={false}
                         defaultCountry="IN"
                         onChange={setphone}
                         onBlur={phoneblur} 
                         rules={{ required: true, validate: isPossiblePhoneNumber }}
                        /> 

                        <div className='errormsg'> {phoneerrmsg ? (
                        <p className="form-error">{phoneerrmsg}</p>
                        ) : null}</div>

                    </div>

                    <Field type="hidden" name="subject" value="Business Analyst" className="form-control"  /> 

                    <div className='mb-3'>
                      <label htmlFor="message" className="">Message*</label>
                      <Field component="textarea" name="message" row="10" aria-labelledby="message" value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      {/* <ErrorMessage name="message" component="div" /> */}
                      <div className='errormsg'> {errors.message && touched.message ? (
                        <p className="form-error">{errors.message}</p>
                      ) : null}</div>
                    </div>
                    <div className='mb-3'>
                      <label htmlFor="message" className="">Upload Resume*</label>
                      <Field id='file-input' type="file" name="resume" 
                      accept=".doc, .docx,.pdf" 
                      onChange={(event) => {
                        const files = event.target.files[0];
                        setFieldValue("resume", files);
                      }}
                      className='form-control' />
                      <div className='errormsg'> {errors.resume && touched.resume ? (
                        <p className="form-error">{errors.resume}</p>
                      ) : null}</div>
                    </div>

                    <div className='text-left mobile-align'>
                    {(hide) ? (
                      <button type="submit" disabled style={{opacity:"0.5"}}>
                         Apply Now
                      </button>
                      
                      ) :  (
                      <button type="submit" >
                         Apply Now
                      </button>
                        )
                      }
                    </div>

                    {/* <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>  */}

                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </Container>

                     {/* success popup */}
                    <Modal
                        show={modalShow}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        >
                        <Modal.Body className='text-center p-5'>
                            {/* {resonseMsg} */}
                            <div className="form-success text-center mt-5 d-none"    dangerouslySetInnerHTML={{ __html: response }}></div>                           
                            <h5>Thank you for applying at Preferred Square. </h5> <p> We will be reviewing your application and our team will get in touch with you.</p>
                             <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                        </Modal.Body>
                    </Modal>


      </section >
    </>
  )
}

export default Jobopeningbody