import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/casestudybody6';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy6 = ({setBlank}) => {

    const headings = "<h4>Navigating and Cultivating </h4><h2 style='display:inline-block;color:#ffffff;'> Employee Engagement</h2><h4>Insights & Interventions for a Leading Conglomerate </h4>";

  return (
    <>
    <Helmet>
        <title>Strategic Investment Blueprint For A Global Pharma Player</title>
        <meta property="og:image" content="https://preferred-square.pages.dev/static/media/case-study-client6.f06fd0423f76c32a1bb2.webp" /> 
        <meta property="og:title" content="Strategic Investment Blueprint For A Global Pharma Player" />
        <meta property='og:description' content='Strategic Investment Blueprint For A Global Pharma Player'/>
        <meta name='keywords' content=''/>
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy6