import React from 'react'
import Container from 'react-bootstrap/Container';
import './headercasestudy.css'

const HeaderCasestudy = (props) => {
  return (
    <header className={`header-casestudy ${props.class}`}> 
       {/* <Container { ${props.class}==="header-casestudy7" ? "fluid" : "" } className='hero px-5 pt-3'> */}
       
       <Container fluid={props.class === "header-casestudy7" ? null : "fluid" } className='hero px-5 pt-3'>
       
       {/* hero */}
       <div className='hero-content'>
       
           <div className='hero-text' dangerouslySetInnerHTML={{__html:props.headings}}>
               {/* <h4>Fast-tracking the investment process through a</h4>
               <h2>structured process</h2> */}
           </div>
       </div>

       </Container>
   </header>

  )
}

export default HeaderCasestudy
