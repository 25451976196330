import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody19 from '../component/Casestudybody19'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client19.webp'
    

const CaseStudy19 = ({setBlank}) => {
    
    const headings = "<h2 style='display:inline-block;padding-left:15px;'>Optimizing Business </h2><h4 style='padding-left:15px;'><span style='display:inline-block;'>Processes</span> For A Real Estate Developer</h4>";

  return (
    <>
    <Helmet>
      <title>Optimizing Business Processes For A Real 
        Estate Developer</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Optimizing Business Processes For A Real Estate Developer " />
      <meta property="og:description" content="Optimizing Business Processes For A Real Estate Developer "/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy19" />  
    <Casestudybody19 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy19
