import React from 'react'
import Menu from '../component/Menu';
import HeaderCasestudy from '../component/HeaderCasestudy';
import Casestudybody from '../component/Casestudybody22';
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const CaseStudy22 = ({setBlank}) => {

    // const headings = "<h2 style='display:inline-block;color:#ffffff;'> DA case study</h2><h4 style='display:inline-block;padding-left:10px;'>DA case study </h4>";
    const headings = "<h2 style='display:inline-block;color:#ffffff;'> DA case study</h2>";

  return (
    <>
        <Helmet>
        <title>DA case study DA case study</title>
        <meta property="og:title" content="DA case study DA case study" />
        <meta property='og:description' content='DA case study DA case study'/>
        <meta name='keywords' content=''/>
        </Helmet>

        <Menu setBlank={setBlank}  />
        <HeaderCasestudy headings={headings} />  
        <Casestudybody />
        <Footer setBlank={setBlank} />
    </>
  )
}

export default CaseStudy22
