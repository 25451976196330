import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom";

import img1 from '../images/case-study-thumb1.webp'
import img2 from '../images/case-study-thumb2.webp'
import img3 from '../images/case-study-thumb3.webp'
import img4 from '../images/case-study-client4.webp'
import img5 from '../images/case-study-client5.webp'
import img6 from '../images/case-study-client6.webp'
import img7 from '../images/case-study-client7.webp'
import img8 from '../images/case-study-client8.webp'
import img9 from '../images/case-study-client9.webp'
import img10 from '../images/case-study-client10.webp'
import img11 from '../images/case-study-client11.webp'
import img12 from '../images/case-study-client12.webp'
import img13 from '../images/case-study-client13.webp'
import img14 from '../images/case-study-client14.webp'
import img15 from '../images/case-study-client15.webp'
import img16 from '../images/case-study-client16.webp'
import img17 from '../images/case-study-client17.webp'
import img18 from '../images/case-study-client18.webp'
import img19 from '../images/case-study-client19.webp'
import img20 from '../images/case-study-client20.webp'
import img21 from '../images/case-study-client21.webp'
// import img22 from '../images/case-study-client22.webp'
import './casestudies.css'

const CaseStudies = ({setBlank}) => {
    // const heading = 'Case Study 1: Fast-tracking';
    // const paragraph = 'The client is a US-based private equity firm with investments in primarily three…';
    // const bttn = 'Find out more about us'
   
    
    // const bttn = 'Read more'

      let creat_url = (str) => {
      str = str.split(/\s+/).join("-");
      str = str.toLowerCase();
      str = "knowledge/" + str;
      return str;
     }
    
     const CaseStudyurl1 = creat_url('Fast Tracking The Investment Process Through A Structured Roadmap');
     const CaseStudyurl2 = creat_url('Enabling A US-Based Hedge Fund To Optimize Its Investment Strategy');
     const CaseStudyurl3 = creat_url('Streamlining Business And Facilitating The Implementation Of An ERP System');
     const CaseStudyurl4 = creat_url('Enabling A Real Estate Conglomerate To Secure USD 40 Mn Funding To Develop A State-Of-The-Art Logistics Park');
     const CaseStudyurl5 = creat_url('Strategic Investment Blueprint For A Global Pharma Player');
     const CaseStudyurl6 = creat_url('Employee Engagement Navigating And Cultivating Insights & Interventions For A Leading Conglomerate');
     const CaseStudyurl7 = creat_url('Corporate Strategy For a Real Estate Development Company');
     const CaseStudyurl8 = creat_url('Strategic Consulting for a PropTech Real Estate Equity Crowdfunding Platform in KSA');
     const CaseStudyurl9 = creat_url('Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S');
     const CaseStudyurl10 = creat_url('Vendor & CounterPartyDue Diligence For A Government-Backed KSA Entity');
     const CaseStudyurl11 = creat_url('Private Equity Valuation Model for an Investment Firm');
     const CaseStudyurl12 = creat_url('Investment Policy Statement for an Endowment Fund');
     const CaseStudyurl13 = creat_url('Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund');
     const CaseStudyurl14 = creat_url('Human Resource Reporting Dashboard For A Company In The Hospitality Industry');
     const CaseStudyurl15 = creat_url('Funding Strategy For A Real Estate Development Subsidiary');     
     const CaseStudyurl16 = creat_url('Enterprise Project Management Office For A Real Estate Development Company');
     const CaseStudyurl17= creat_url('Consolidated Financial Model  for a KSA-Based Real Estate Development Project');
     const CaseStudyurl18= creat_url('Investment Memorandum for an Investment Firm');
     const CaseStudyurl19= creat_url('Optimizing Business Processes For A Real Estate Developer');
     const CaseStudyurl20 = creat_url('Financial Due Diligence For A U.S.-Based Private Equity Firm');
     const CaseStudyurl21 = creat_url('Corporate Strategy For A Single-Family Office');
    //  const CaseStudyurl22 = creat_url('DA case study DA case study');
    
 

    const casestudies = [
        { 
            id: 1,
            heading: 'Fast tracking the investment process through a structured Roadmap', 
            paragraph: 'The client is a US-based private equity firm with investments in primarily three asset classes - credit, real assets and listed equities', 
            img: img1,
            link:`/${CaseStudyurl1}`
        },
        { 
            id: 2,
            heading: 'Enabling a US-based hedge fund to optimize its investment strategy', 
            paragraph: 'A US-based Hedge Fund with over USD 2 Bn in assets under management and a focus on generating Alpha over and above market returns', 
            img: img2,
            link:`/${CaseStudyurl2}`
        },
        { 
            id: 3,
            heading: 'Streamlining business and facilitating the implementation of an ERP system', 
            paragraph: 'A leading real estate developer in the Middle East, specializing in developing high-end, experiential real estate projects across Saudi Arabia and UAE', 
            img: img3,
            link:`/${CaseStudyurl3}`
        },
        { 
          id: 4,
          heading: 'Enabling a Real Estate Conglomerate To Secure USD 40 Mn funding To Develop A State-of-the-art logistics park', 
          paragraph: 'A leading real estate master developer specializing in high-end experiential mixed-use real estate and mega-infra projects. ', 
          img: img4,
          link:`/${CaseStudyurl4}`
        },
        { 
          id: 5,
          heading: 'Strategic Investment Blueprint For A Global Pharma Player', 
          paragraph: 'The client operates as a licensed Biopharmaceutical manufacturing and marketing firm. They are committed to leading patient-centric care by addressing significant gaps in the biopharma products landscape.', 
          img: img5,
          link:`/${CaseStudyurl5}`
        },
        { 
          id: 6,
          heading: 'Employee Engagement Navigating and Cultivating Insights & Interventions for a Leading Conglomerate', 
          paragraph: 'The client operates as a licensed Biopharmaceutical manufacturing and marketing firm. They are committed to leading patient-centric care by addressing significant gaps in the biopharma products landscape.', 
          img: img6,
          link:`/${CaseStudyurl6}`
        },
        { 
          id: 7,
          heading: 'Corporate Strategy For a Real Estate Development Company', 
          paragraph: 'The client is a real estate development company, established in 2021 and owned by a renowned sovereign wealth fund. They operate across a spectrum of segments including Residential, Commercial, Hospitality, Retail, Warehouse, Parking, Social Infrastructure, and more.', 
          img: img7,
          link:`/${CaseStudyurl7}`
        },
        { 
          id: 8,
          heading: 'Strategic Consulting For A PropTech Real Estate Equity Crowdfunding Platform In KSA', 
          paragraph: 'The client, a PropTech startup in the Kingdom of Saudi Arabia (KSA), specializes in real estate equity crowdfunding. Their platform is designed to democratize access to real estate investment, allowing individuals to invest in vetted, high-quality projects at a smaller ticket size, while also aiding real estate companies in crowdfunding their projects. About the client:', 
          img: img8,
          link:`/${CaseStudyurl8}`
        },
        { 
          id: 9,
          heading: 'Industry Research for an HNI Launching a New Coffee Roastery Brand in the U.S.', 
          paragraph: 'The client is an American HNI (High Net-worth Individual) with significant investment holdings in the consumer discretionary and allied sectors.', 
          img: img9,
          link:`/${CaseStudyurl9}`
        },
        { 
          id: 10,
          heading: 'Vendor & CounterParty Due Diligence For A Government-Backed KSA Entity', 
          paragraph: 'The client operates as a government-backed entity in the Kingdom of Saudi Arabia (KSA), with a mission to boost tourism in the region, generate employment opportunities, and help diversify the government’s sources of income.', 
          img: img10,
          link:`/${CaseStudyurl10}`
        },
        { 
          id: 11,
          heading: 'Private Equity Valuation Model For An Investment Firm', 
          paragraph: 'The client is an investment firm seeking to expand its investment portfolio across real estate and non-real estate asset classes, including both private and public equity markets.', 
          img: img11,
          link:`/${CaseStudyurl11}`
        },
        { 
          id: 12,
          heading: 'Investment Policy Statement For An Endowment Fund', 
          paragraph: 'The client is an endowment fund established 15 years ago with the aim of preserving, enhancing, and transferring wealth to upcoming generations. The fund manages an AUM of USD 35 Mn invested in a diversified portfolio of traditional and alternative asset classes.', 
          img: img12,
          link:`/${CaseStudyurl12}`
        },
        { 
          id: 13,
          heading: 'Equity Valuation & Fairness Opinion Exercise For A US-Based Hedge Fund', 
          paragraph: 'The client is a US-based hedge fund with $250 million in AUM. The fund leverages a long-short investment strategy to generate alpha, or excess returns, that outperform the broader market.',
          img: img13,
          link:`/${CaseStudyurl13}`
        },
        { 
          id: 14,
          heading: 'Human Resource Reporting Dashboard For A Company In The Hospitality Industry', 
          paragraph: 'The client is a closed joint stock company in the hospitality sector owned by a Sovereign Wealth Fund. They specialize in creating unique travel experiences through eco-tourism, agri-tourism, and adventure tourism.',
          img: img14,
          link:`/${CaseStudyurl14}`
        },
        { 
          id: 15,
          heading: 'Funding Strategy For A Real Estate Development Subsidiary', 
          paragraph: 'The client is a wholly-owned real estate development subsidiary of a Sovereign Wealth Fund, tasked with restoring a UN-recognized historically significant district.',
          img: img15,
          link:`/${CaseStudyurl15}`
        },
        { 
          id: 16,
          heading: 'Enterprise Project Management Office For A Real Estate Development Company', 
          paragraph: 'The client is a subsidiary of a sovereign wealth fund that functions as a real estate development company. With a mandate totaling approximately USD 20.0 billion, the company operates across diverse segments within the real estate sector.',
          img: img16,
          link:`/${CaseStudyurl16}`
        },
        { 
          id: 17,
          heading: 'Consolidated Financial Model  for a KSA-Based Real Estate Development Project', 
          paragraph: 'The client is a KSA-based real estate developer focused on preservation and redevelopment projects to revitalize a historically significant region comprising approximately 1,400 assets.',
          img: img17,
          link:`/${CaseStudyurl17}`
        },
        { 
          id: 18,
          heading: 'Investment Memorandum for an Investment Firm', 
          paragraph: 'The client is a state-owned investment firm, managing a portfolio exceeding USD 2.5 billion. This portfolio includes an array of real estate investments throughout the Southeast Asia region.',
          img: img18,
          link:`/${CaseStudyurl18}`
        },
        { 
          id: 19,
          heading: 'Optimizing Business Processes For A Real Estate Developer', 
          paragraph: 'The client is a leading real estate developer in the Middle East specializing in developing high-end experiential real estate projects across Saudi Arabia and the United Arab Emirates.',
          img: img19,
          link:`/${CaseStudyurl19}`
        },
        { 
          id: 20,
          heading: 'Financial Due Diligence For A U.S.-Based Private Equity Firm', 
          paragraph: 'The client is a U.S.-based private equity firm specializing in small-ticket investments ranging from USD 10 to USD 20 million. They primarily invest in companies with strong growth potential and competitive market positioning.',
          img: img20,
          link:`/${CaseStudyurl20}`
        },
        { 
          id: 21,
          heading: 'Corporate Strategy For A Single-Family Office', 
          paragraph: 'The client is a family office governing and managing a multi-asset class portfolio worth USD 15 billion.',
          img: img21,
          link:`/${CaseStudyurl21}`
        },
        // { 
        //   id: 22,
        //   heading: 'DA case study DA case study', 
        //   paragraph: 'TThe client is a family office governing and managing a multi-asset class portfolio worth USD 15 billion.',
        //   img: img22,
        //   link:`/${CaseStudyurl22}`
        // },
        
      ]

      const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 600);
    }


    
    return (
        <>
            <Container className='casestudy-section desktop'>
                <Row>
                    
                    
                    {/* <Col className='col-md-4 mt-4 mb-5 pb-4'>
                        <div className='p-2'>
                            <div>
                            <NavLink to="/casestudy1" onClick={scrollToTop}>
                                <img src={img1} alt='' />
                            </NavLink>
                            </div>
                            <div>
                            <NavLink to="/casestudy1" onClick={scrollToTop}>
                                  <h2 className='mt-4'>{heading}</h2>
                                <p className='mt-4'>{paragraph}</p>
                                <p className='anima'><span className='anima-underline' > **** </span></p>
                            </NavLink>
                            </div>
                            <button className='mt-4'>{bttn}</button>
                        </div>
                    </Col> */}

                    {

                    casestudies.reverse().map((d) => (
                        <Col key={d.index} className='col-md-4 mt-4 mb-5 pb-4'>
                        <div className='p-2'>
                            <div>
                            <NavLink to={d.link} onClick={scrollToTop}>
                                <img src={d.img} alt='' />
                            </NavLink>
                            </div>
                            <div>
                            <NavLink to={d.link} onClick={scrollToTop}>
                                  <h2 className='mt-4'>{d.heading}</h2>
                                <p className='mt-4'>{d.paragraph}</p>
                                <p className='anima'><span className='anima-underline' > **** </span></p>
                            </NavLink>
                            </div>
                            <NavLink to={d.link} onClick={scrollToTop}>
                            <button className='mt-4'>Read more</button>
                            </NavLink>
                        </div>
                    </Col>
                

                    ))    
                    }

                    {/* <Col className='col-md-4 mt-4 mb-5 pb-4'>
                        <div className='p-2'>
                            <div>
                                <img src={img1} alt='' />
                            </div>
                            <div>
                                <h2 className='mt-4'>{heading}</h2>
                                <p className='mt-4'>{paragraph}</p>
                                <p className='anima'><span className='anima-underline' > **** </span></p>
                            </div>
                            <button className='mt-4'>{bttn}</button>
                        </div>
                    </Col>
                    <Col className='col-md-4 mt-4 mb-5 pb-4'>
                        <div className='p-2'>
                            <div>
                                <img src={img1} alt='' />
                            </div>
                            <div>
                                <h2 className='mt-4'>{heading}</h2>
                                <p className='mt-4'>{paragraph}</p>
                                <p className='anima'><span className='anima-underline' > **** </span></p>
                            </div>
                            <button className='mt-4'>{bttn}</button>
                        </div>
                    </Col>
                     */}
                </Row>
            </Container>



            {
                casestudies.map((d) => (
            
                    <Row key={d.index} className='m-0 mt-2 g-0 mobile'>
                    <Col className='col-md-6 mobile'>
                    <NavLink to={d.link} onClick={scrollToTop}>
                       <div className='sol-box white ser-box3' style={{ backgroundImage: `url(${d.img})` }}>
                        <div className='text-box'>
                          <p className='p-5 empty-para'>
                          </p>
                        </div>
  
                      </div>
                      </NavLink>
                    </Col>
                    <Col className='col-md-6 mobile'>
                      <div className='sol-box white d-bg-box '>
                        <div className='p-5 mobilepadding'>
                        <NavLink to={d.link} onClick={scrollToTop}>
                          <h4>{d.heading}</h4>
                          <p>{d.paragraph} </p>
                          <p className='anima'><span className='anima-underline' > **** </span></p>
                       </NavLink>
                          <div className='mob-align'>
                            <NavLink to={d.link} onClick={scrollToTop}>
                              <button className="findout-btn mt-3 ">Read more</button>
                            </NavLink>  </div>
                        </div>
                      </div>
                    </Col>
              </Row>
  
                    
                ))
            }         

            {/* <Row className='m-0 g-0 mobile'>
                  <Col className='col-md-6 mobile'>
                  <NavLink to="/casestudy1" onClick={scrollToTop}>
                     <div className='sol-box white ser-box3' style={{ backgroundImage: `url(${img1})` }}>
                      <div className='text-box'>
                        <p className='p-5 empty-para'>
                        </p>
                      </div>

                    </div>
                    </NavLink>
                  </Col>
                  <Col className='col-md-6 mobile'>
                    <div className='sol-box white d-bg-box '>
                      <div className='p-5 mobilepadding'>
                      <NavLink to="/casestudy1" onClick={scrollToTop}>
                        <h4>{heading}</h4>
                        <p>{paragraph} </p>
                        <p className='anima'><span className='anima-underline' > **** </span></p>
                     </NavLink>
                        <div className='mob-align'>
                          <NavLink to="/about" onClick={scrollToTop}>
                            <button className="findout-btn mt-3 ">Find out more about us</button>
                          </NavLink>  </div>
                      </div>
                    </div>
                  </Col>
            </Row> */}

        </>
    )
}

export default CaseStudies