import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../component/Menu'
import HeaderCasestudy from '../component/HeaderCasestudy'
import Casestudybody17 from '../component/Casestudybody17'
import Footer from '../component/Footer'
import thumbimg from '../images/case-study-client17.webp'
    

const CaseStudy17 = ({setBlank}) => {
    
    const headings = "<h2 style='display:inline-block;padding-left:15px;'>Consolidated Financial Model </h2><h4>for a KSA-Based Real Estate Development Project</h4>";

  return (
    <>
    <Helmet>
      <title>Consolidated Financial Model for a KSA-Based Real Estate Development Project</title>
      <meta property="og:image" content={thumbimg} /> 
      <meta property="og:title" content="Consolidated Financial Model for a KSA-Based Real Estate Development Project" />
      <meta property="og:description" content="Consolidated Financial Model for a KSA-Based Real Estate Development Project"/>
    </Helmet>

    <Menu setBlank={setBlank} />
    <HeaderCasestudy headings={headings} class="header-casestudy17" />  
    <Casestudybody17 />
    <Footer setBlank={setBlank} />
</>

  )
}

export default CaseStudy17
