import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './casestudybody19.css';

import clientimg from '../images/case-study-client19.webp'
import outcome from '../images/outcome19.webp'

import sm1 from '../images/cs19so1.webp'
import sm2 from '../images/cs19so2.webp'
import sm3 from '../images/cs19so3.webp'
import sm4 from '../images/cs19so4.webp'



const casestudybody10 = () => {
    return (
        <>
        <section className='casestudybody19-section' >
          <Container>
            <Row className='w-100 py-md-5 py-4 clintrow'>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <h2>About</h2>
                      {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                      <div className="h1">The Client</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>The client is a real estate developer specializing in developing high-end experiential real estate projects.</p>
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={clientimg} alt="" />
                </div>
            </Row>
       
            <Row className='mt-md-5'>
              <div className='col-md-12'>
                <div className='content-box'>
                  <h2>Situation</h2>
                  {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                  <div className="h1">Overview</div>
                  <p className='anima '><span className='only-anima-underline' > **** </span></p>
                  <p>The client recognized the need to assess their current business processes to enhance operational efficiency and identify gaps pertaining to the reporting framework to implement an ERP system.</p>

                  {/* <ul className='overview-list'>
                    <li>Develop and present a compelling business case to the Investment Committee, underlining the feasibility and potential profitability of the venture</li>
                    <li>Execute an industry analysis, ensuring a well-informed Go-To-Market (GTM) strategy that aligned with the dynamics and opportunities within the coffee industry</li>
                  </ul> */}

                </div>
                
              </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody19-section bg-gredient-dark'>
          <Container>
            <Row>
              <div className='col-md-12'>
                  <div className='content-box'>
                    <h2>Approach &</h2>
                    {/* <div className="h1"><span className='h1 animate-underline' style={{ paddingBottom: "40px" }}>The Client</span></div> */}
                    <div className="h1">Methodology</div>
                    <p className='anima '><span className='only-anima-underline' > **** </span></p>
                    <p>We employed a three-phase assessment approach to ensure a comprehensive evaluation of the client's business processes:</p>
                    
                    <div className='method-stage'> <span>PHASE 01 </span>Current State Assessment</div>
                
                    <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                            <path d="M27.3525 36H23.0752V21.3516H18.0801V18.5098C19.3984 18.4512 20.3213 18.3633 20.8486 18.2461C21.6885 18.0605 22.3721 17.6895 22.8994 17.1328C23.2607 16.752 23.5342 16.2441 23.7197 15.6094C23.8271 15.2285 23.8809 14.9453 23.8809 14.7598H27.3525V36Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Process Mapping</h4>
                          <p>Mapped the existing business processes for all functions. This involved mapping out the roles and responsibilities associated with each sub-process and activity, as well as the output generated at each stage.</p>
                        </div>
                      </div>
    
                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M26.4297 19.0811C25.9316 18.4854 25.2188 18.1875 24.291 18.1875C23.0215 18.1875 22.1572 18.6611 21.6982 19.6084C21.4346 20.1553 21.2783 21.0244 21.2295 22.2158H17.1719C17.2402 20.4092 17.5674 18.9492 18.1533 17.8359C19.2666 15.7168 21.2441 14.6572 24.0859 14.6572C26.332 14.6572 28.1191 15.2822 29.4473 16.5322C30.7754 17.7725 31.4395 19.418 31.4395 21.4688C31.4395 23.041 30.9707 24.4375 30.0332 25.6582C29.418 26.4688 28.4072 27.3721 27.001 28.3682L25.3311 29.5547C24.2861 30.2969 23.5684 30.834 23.1777 31.166C22.7969 31.498 22.4746 31.8838 22.2109 32.3232H31.4834V36H16.9375C16.9766 34.4766 17.3037 33.085 17.9189 31.8252C18.5146 30.4092 19.9209 28.9102 22.1377 27.3281C24.0615 25.9512 25.3066 24.9648 25.873 24.3691C26.7422 23.4414 27.1768 22.4258 27.1768 21.3223C27.1768 20.4238 26.9277 19.6768 26.4297 19.0811Z" fill="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Identifying business challenges</h4>
                          <p>Met with key stakeholders (business function owners) to gain a deep understanding of the current challenges they faced within the existing procedures.</p>
                        </div>
                      </div>



                      <div className='method-stage mt-5'> <span>PHASE 02 </span>Gap Assessment</div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                            <path d="M27.3525 36H23.0752V21.3516H18.0801V18.5098C19.3984 18.4512 20.3213 18.3633 20.8486 18.2461C21.6885 18.0605 22.3721 17.6895 22.8994 17.1328C23.2607 16.752 23.5342 16.2441 23.7197 15.6094C23.8271 15.2285 23.8809 14.9453 23.8809 14.7598H27.3525V36Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Benchmarking</h4>
                          <p>Compared the existing policies and procedures against industry best practices to identify any gaps or areas for improvement.</p>
                        </div>
                      </div>
    
                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M26.4297 19.0811C25.9316 18.4854 25.2188 18.1875 24.291 18.1875C23.0215 18.1875 22.1572 18.6611 21.6982 19.6084C21.4346 20.1553 21.2783 21.0244 21.2295 22.2158H17.1719C17.2402 20.4092 17.5674 18.9492 18.1533 17.8359C19.2666 15.7168 21.2441 14.6572 24.0859 14.6572C26.332 14.6572 28.1191 15.2822 29.4473 16.5322C30.7754 17.7725 31.4395 19.418 31.4395 21.4688C31.4395 23.041 30.9707 24.4375 30.0332 25.6582C29.418 26.4688 28.4072 27.3721 27.001 28.3682L25.3311 29.5547C24.2861 30.2969 23.5684 30.834 23.1777 31.166C22.7969 31.498 22.4746 31.8838 22.2109 32.3232H31.4834V36H16.9375C16.9766 34.4766 17.3037 33.085 17.9189 31.8252C18.5146 30.4092 19.9209 28.9102 22.1377 27.3281C24.0615 25.9512 25.3066 24.9648 25.873 24.3691C26.7422 23.4414 27.1768 22.4258 27.1768 21.3223C27.1768 20.4238 26.9277 19.6768 26.4297 19.0811Z" fill="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Standardization Framework</h4>
                          <p>Established a structured framework for the company's policies and business processes.</p>
                        </div>
                      </div>        

                      <div className='method-stage mt-5'> <span>PHASE 03 </span>Designing and Drafting PPM</div>

                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                            <path d="M27.3525 36H23.0752V21.3516H18.0801V18.5098C19.3984 18.4512 20.3213 18.3633 20.8486 18.2461C21.6885 18.0605 22.3721 17.6895 22.8994 17.1328C23.2607 16.752 23.5342 16.2441 23.7197 15.6094C23.8271 15.2285 23.8809 14.9453 23.8809 14.7598H27.3525V36Z" fill="white"/>
                        </svg>


                        </div>
                        <div className='text'>
                          <h4>Procedures Manual (PPM)</h4>
                          <p>Drafted a comprehensive Procedures Manual (PPM) that outlined the optimized processes for the entire organization.</p>
                        </div>
                      </div>
    
                      <div className='list-text last my-3'>
                        <div className='icon'>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25" cy="25" r="24.5" fill="#03A393" stroke="white"/>
                          <path d="M26.4297 19.0811C25.9316 18.4854 25.2188 18.1875 24.291 18.1875C23.0215 18.1875 22.1572 18.6611 21.6982 19.6084C21.4346 20.1553 21.2783 21.0244 21.2295 22.2158H17.1719C17.2402 20.4092 17.5674 18.9492 18.1533 17.8359C19.2666 15.7168 21.2441 14.6572 24.0859 14.6572C26.332 14.6572 28.1191 15.2822 29.4473 16.5322C30.7754 17.7725 31.4395 19.418 31.4395 21.4688C31.4395 23.041 30.9707 24.4375 30.0332 25.6582C29.418 26.4688 28.4072 27.3721 27.001 28.3682L25.3311 29.5547C24.2861 30.2969 23.5684 30.834 23.1777 31.166C22.7969 31.498 22.4746 31.8838 22.2109 32.3232H31.4834V36H16.9375C16.9766 34.4766 17.3037 33.085 17.9189 31.8252C18.5146 30.4092 19.9209 28.9102 22.1377 27.3281C24.0615 25.9512 25.3066 24.9648 25.873 24.3691C26.7422 23.4414 27.1768 22.4258 27.1768 21.3223C27.1768 20.4238 26.9277 19.6768 26.4297 19.0811Z" fill="white"/>
                        </svg>

                        </div>
                        <div className='text'>
                          <h4>Process Flow Diagrams (PFDs)</h4>
                          <p>Process Flow Diagrams (PFDs) were designed to represent the steps involved in each process visually.</p>
                        </div>
                      </div>                

                    
                
                      <h3 className='mt-5'>Sample Output</h3>
                      <div className='sample-output1 mt-3'>
                        <img src={sm1} alt=''/>
                        <img src={sm2} alt=''/>
                      </div>
                      <div className='sample-output mt-3'>
                        <img src={sm3} alt=''/>
                        <img src={sm4} alt=''/>
                      </div>

                  </div>
                  
                </div>
            </Row>
          </Container>
        </section>
    
        <section className='casestudybody19-section outcome'  >
          <Container>
            <Row className='w-100 pb-5 clintrow '>
                <div className='col-md-4 relative order-1'>
                  <div className='left-box'>
                    <div className='content-box'>
                      <div className="h1">Outcome</div>
                      <p className='anima '><span className='only-anima-underline' > **** </span></p>
                      <p>We streamlined the client's business processes by analyzing and improving existing workflows, increasing efficiency and productivity. Our insights facilitated the implementation of their ERP systems, ensuring smooth integration and data consistency within the new system.</p>  
                    
                    </div>
                  </div>
                           
                </div>
                <div className='col-md-8 right-box'>
                    <img src={outcome} alt="" />
                </div>
            </Row>
       
          </Container>
        </section>
    
        </>  
      )
}

export default casestudybody10
