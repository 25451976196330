import React from 'react';
import { NavLink } from "react-router-dom";

import './JobOpeningsSection.css'

const JobOpeningsSection = () => {
  return (
    <div id="jobopening" className="job-openings-main-section">
        <div className="jobopenings-inner-section">
            <div className="job-openings-inner-rows">
               <div className="container">
                    <div className="row job-openings-row align-items-center">
                        <div className="col-md-6">
                            <div className="left-side-openings-header">
                                <div className="left-job-open-column-inner position-relative">
                                    <div className="job-absolute-text">
                                        <p>Job</p>
                                    </div>
                                    <div className="latest-p-section">
                                        <p className='text-white mb-0'>Latest</p>
                                    </div>
                                    <div className="bottom-jobber-section">
                                        <h2 className='text-white mb-0'>Job <span>Openings</span></h2>
                                    </div>
                                    <div className="bottom-jobber-section-mobile-viewer">
                                        <h2 className='text-white  mb-0'>Job Openings</h2>
                                        <div className="job-animator-we-bottom-767-after">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="right-side-job-opening-cards">
                                <div className="right-side-cards-job d-flex flex-column">
                                   <div className="right-jobs-first-card similar-cards-section-jobber">
                                      <div className="card-images-section position-relative">
                                        <img src={require('../images/job-card-1.webp')} className='w-100' alt="" />
                                        <div className="bottom-absolute-section-desc position-absolute w-100 bottom-0">
                                            <div className="two-sections-divider d-flex align-items-center justify-content-between">
                                                <div className="left-busy-head">
                                                    <h2>Business Analyst</h2>
                                                </div>
                                                <div className="right-linked-in-content">
                                                    <NavLink to="/businessanalyst" className='job-sec-link d-flex align-items-center'>
                                                        <span>Job Description</span> 
                                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 6.5V4H11V0.5L16 5.5L11 10.5V6.5H0Z" fill="#25635C"/>
                                                        </svg>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                      </div>  
                                   </div>
                                   <div className="right-jobs-second-card similar-cards-section-jobber">
                                        <div className="card-images-section position-relative">
                                            <img src={require('../images/job-card-2.webp')} className='w-100' alt="" />
                                            <div className="bottom-absolute-section-desc position-absolute w-100 bottom-0">
                                                <div className="two-sections-divider d-flex align-items-center justify-content-between">
                                                    <div className="left-busy-head">
                                                        <h2>Data Analyst</h2>
                                                    </div>
                                                    <div className="right-linked-in-content">
                                                        <NavLink to="/dataanalyst" className='job-sec-link d-flex align-items-center'>
                                                            <span>Job Description</span> 
                                                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0 6.5V4H11V0.5L16 5.5L11 10.5V6.5H0Z" fill="#25635C"/>
                                                            </svg>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </div>
  )
}

export default JobOpeningsSection
